<template>
  <v-container style="min-height: 100vh;">
    <div class="d-flex flex-row flex-grow-1 mb-3">
      <PageTitle
        text="System Settings"
        class="ft ml-n2 font-size-sm black--text"
      />
    </div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(updateSettings)">
        <div
          class="d-flex flex-row flex-grow-1 flex-wrap  justify-space-between"
        >
          <div
            class="d-flex flex-column flex-grow-1 mr-1"
            style="flex-basis: 50%;"
          >
            <span class=" font font-weight-bold font-size-lg mt-2"
              >Branch Details</span
            >
            <span class="font font-weight-medium font-size-md mb-2"
              >Update branch details here</span
            >
            <div class="d-flex flex-column flex-grow-1 pa-2 grey lighten-4">
              <v-card class=" box-shadow-light pa-3">
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(updateBranch)">
                    <div class="d-flex flex-row flex-grow-1 mt-3">
                      <ValidationProvider
                        rules="required"
                        v-slot="{ errors }"
                        tag="div"
                        class="d-flex flex-grow-1"
                      >
                        <v-text-field
                          autofocus
                          :error-messages="errors[0]"
                          readonly
                          outlined
                          v-model="branch.name"
                          prepend-icon="account_circle"
                          label="Full Name"
                          class=" font font-weight-medium font-size-md ma-1"
                        />
                      </ValidationProvider>
                      <ValidationProvider
                        tag="div"
                        class="d-flex flex-grow-1 ma-1"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-autocomplete
                          outlined
                          prepend-icon="map"
                          :error-messages="errors[0]"
                          :items="regions"
                          v-model="branch.region"
                          class=" font font-weight-medium font-size-md"
                          label="Region"
                        >
                          <template #item="{item}">
                            <span class="font font-weight-medium font-size-sm">
                              {{ item }}
                            </span>
                          </template>
                        </v-autocomplete>
                      </ValidationProvider>
                    </div>
                    <div class="d-flex flex-row flex-grow-1">
                      <ValidationProvider
                        tag="div"
                        v-slot="{ errors }"
                        rules="required"
                        class="d-flex flex-grow-1"
                      >
                        <v-text-field
                          autofocus
                          outlined
                          prepend-icon="location_on"
                          v-model="branch.location"
                          label="Location"
                          :error-messages="errors[0]"
                          class=" font font-weight-medium font-size-md ma-1"
                        />
                      </ValidationProvider>
                      <ValidationProvider
                        tag="div"
                        class="d-flex flex-grow-1 ma-1"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          autofocus
                          outlined
                          prepend-icon="phone"
                          v-model="branch.telephoneNumber"
                          label="Phone Number"
                          :error-messages="errors[0]"
                          class=" font font-weight-medium font-size-md"
                        />
                      </ValidationProvider>
                    </div>
                    <div
                      class="d-flex flex-row flex-grow-1"
                      style="justify-content: flex-end;"
                    >
                      <v-btn
                        type="submit"
                        color="primary"
                        :loading="loading"
                        :disabled="loading"
                        class="font font-weight-medium font-size-md"
                      >
                        Save
                      </v-btn>
                    </div>
                  </form>
                </ValidationObserver>
              </v-card>
            </div>
          </div>
          <div
            class="d-flex flex-column flex-grow-1 mr-1"
            style="flex-basis: 50%;"
          >
            <span class=" font font-weight-bold font-size-lg mt-2"
              >Account</span
            >
            <span class="font font-weight-medium font-size-md mb-2"
              >Update personal information here</span
            >
            <div class="d-flex flex-column flex-grow-1 pa-2 grey lighten-4">
              <v-card class=" box-shadow-light pa-3">
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(updateProfile)">
                    <div class="d-flex flex-row flex-grow-1 mt-3">
                      <ValidationProvider tag="div" class="d-flex flex-grow-1">
                        <v-text-field
                          autofocus
                          outlined
                          v-model="user.name"
                          prepend-icon="account_circle"
                          label="Full Name"
                          class=" font font-weight-medium font-size-md ma-1"
                        />
                      </ValidationProvider>
                      <ValidationProvider
                        tag="div"
                        class="d-flex flex-grow-1 ma-1"
                      >
                        <v-text-field
                          autofocus
                          prepend-icon="email"
                          outlined
                          v-model="user.email"
                          label="Email Address"
                          disabled
                          class=" font font-weight-medium font-size-md"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="d-flex flex-row flex-grow-1">
                      <ValidationProvider tag="div" class="d-flex flex-grow-1">
                        <v-text-field
                          autofocus
                          outlined
                          prepend-icon="people"
                          v-model="user.username"
                          label="Username"
                          class=" font font-weight-medium font-size-md ma-1"
                        />
                      </ValidationProvider>
                      <ValidationProvider
                        tag="div"
                        class="d-flex flex-grow-1 ma-1"
                      >
                        <v-text-field
                          autofocus
                          outlined
                          prepend-icon="phone"
                          v-model="user.mobile"
                          label="Phone Number"
                          class=" font font-weight-medium font-size-md"
                        />
                      </ValidationProvider>
                    </div>
                    <div
                      class="d-flex flex-row flex-grow-1"
                      style="justify-content: flex-end;"
                    >
                      <v-btn
                        type="submit"
                        color="primary"
                        :loading="loading"
                        :disabled="loading"
                        class="font font-weight-medium font-size-md"
                      >
                        Save
                      </v-btn>
                    </div>
                  </form>
                </ValidationObserver>
              </v-card>
            </div>
          </div>
          <div
            class="d-flex flex-column flex-grow-1 mr-1 mt-3"
            style="flex-basis: 50%;"
          >
            <span class=" font font-weight-bold font-size-lg mt-2"
              >Change Password</span
            >
            <span class="font font-weight-medium font-size-md mb-2"
              >You can change your password here.</span
            >
            <form @submit.prevent="handleSubmit(changePassword)">
              <div class="d-flex flex-row flex-grow-1 mt-3">
                <ValidationProvider
                  tag="div"
                  v-slot="{ errors }"
                  rules="required"
                  class="d-flex flex-grow-1"
                >
                  <v-text-field
                    outlined
                    type="password"
                    :error-messages="errors[0]"
                    v-model="password.oldPassword"
                    prepend-icon="lock"
                    label="Enter your old password."
                    class=" font font-weight-medium font-size-md ma-1"
                  />
                </ValidationProvider>
                <ValidationProvider
                  tag="div"
                  rules="required|password:@confirm"
                  v-slot="{ errors }"
                  class="d-flex flex-grow-1"
                >
                  <v-text-field
                    outlined
                    type="password"
                    v-model="password.newPassword"
                    :error-messages="errors[0]"
                    prepend-icon="lock"
                    label="Enter your new password."
                    class=" font font-weight-medium font-size-md ma-1"
                  />
                </ValidationProvider>
                <ValidationProvider
                  tag="div"
                  rules="required"
                  v-slot="{ errors }"
                  name="confirm"
                  class="d-flex flex-grow-1"
                >
                  <v-text-field
                    outlined
                    type="password"
                    :error-messages="errors[0]"
                    v-model="password.confirmPassword"
                    prepend-icon="lock"
                    label="Enter your confirm password."
                    class=" font font-weight-medium font-size-md ma-1"
                  />
                </ValidationProvider>
              </div>
              <div class="d-flex flex-row flex-grow-1 justify-end">
                <v-btn
                  color="primary"
                  type="submit"
                  :loading="loading"
                  :disabled="loading"
                >
                  <span class="font font-weight-medium font-size-sm"
                    >Change Password</span
                  >
                </v-btn>
              </div>
            </form>
          </div>

          <div class="d-flex flex-column flex-grow-1 pt-4 mb-2">
            <v-alert
              type="error"
              dense
              class="font font-weight-bold font-size-md mb-2"
            >
              Danger Zone
            </v-alert>
            <div class="d-flex flex-row" style="border-radius: 5px">
              <v-checkbox
                color="primary"
                v-model="inMaintenanceMode"
                class="pa-0 mx-2 white--text"
              >
                <template #label>
                  <span
                    class=" font black--text font-size-sm font-weight-bold mx-2"
                  >
                    Switch to maintenance mode from here so that
                    <kbd>System Administrator</kbd> can assist or do some
                    updates.</span
                  >
                </template>
              </v-checkbox>
              <v-btn
                color="error"
                @click.stop="setMaintenanceMode"
                class="mt-3"
                small
              >
                <span
                  class="font font-weight-medium font-size-sm text-capitalize"
                  >Save</span
                >
              </v-btn>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </v-container>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { numeric, required } from "vee-validate/dist/rules";
import SnackBarMixins from "../../mixins/SnackBarMixins";
import { mapGetters } from "vuex";
import { of } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
extend("required", {
  ...required,
  message: "Input field is required"
});
extend("numeric", {
  ...numeric,
  message: "Field must only contain numbers"
});
extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Password confirmation does not match"
});
export default {
  name: "SystemSettings",
  components: {
    ValidationObserver,
    ValidationProvider,
    PageTitle: () => import(`../../components/PageTitle`),
    SnackBar: () => import(`../../components/SnackBar`)
  },
  data() {
    return {
      regions: [
        "Ahafo",
        "Ashanti",
        "Bono",
        "Bono East",
        "Central",
        "Eastern",
        "Greater Accra",
        "Northern",
        "North East",
        "Oti ",
        "Savannah",
        "Upper East",
        "Upper West",
        "Volta",
        "Western",
        "Western North"
      ],
      user: {},
      password: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
      },
      branch: {
        id: null,
        name: "",
        telephoneNumber: "",
        region: "",
        location: ""
      },
      inMaintenanceMode: false,
      maintenanceState: false
    };
  },
  mixins: [SnackBarMixins],
  computed: {
    ...mapGetters({
      loading: "getIsLoading",
      settings: "settings/getSystemSettings",
      profileDetails: "auth/getProfileDetails",
      branchDetails: "settings/getBranchDetails"
    })
  },
  watch: {
    inMaintenanceMode(payload) {
      of(payload)
        .pipe(
          map(key => key),
          debounceTime(500)
        )
        .subscribe(response => {
          console.log(response);
        });
    },
    profileDetails(payload) {
      this.user = { ...payload };
    },
    branchDetails(payload) {
      const {
        id,
        name,
        telephoneNumber,
        inMaintenanceMode,
        location,
        region
      } = payload;
      this.branch = { id, name, telephoneNumber, region, location };
      this.inMaintenanceMode = this.maintenanceState = inMaintenanceMode;
    }
  },
  methods: {
    updateProfile() {
      // eslint-disable-next-line no-unused-vars
      const { id, role, branchId, ...rest } = this.user;
      this.$store.dispatch("auth/updateProfile", rest);
    },
    updateBranch() {
      this.$store.dispatch("settings/updateBranch", this.branch);
    },
    changePassword() {
      this.$store.dispatch("auth/changePassword", this.password);
    },
    setMaintenanceMode() {
      console.log(this.inMaintenanceMode);
      this.$store.dispatch(
        "settings/setMaintenanceMode",
        this.inMaintenanceMode
      );
    }
  },
  created() {
    this.$store.dispatch("auth/me");
    this.$store.dispatch("settings/branch");
  }
};
</script>
